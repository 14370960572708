import { createTheme } from "@mui/material"

export const materialTheme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      light: "#F5FAFF",
      main: "#18539e",
      dark: "#002884",
      contrastText: "#fff",
    },
    text: {
      primary: "#000000",
      secondary: "rgba(63, 63, 63, 0.6)",
      disabled: "rgba(63, 63, 63, 0.38)",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
})
