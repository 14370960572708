export function getBrowserLang(lang?: string): string | undefined {
  switch (lang) {
    case "en":
    case "en-US":
      return "en"
    case "ja":
    case "ja-JP":
      return "ja"
  }
}
