import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import en from "@/locales/en/strings.json"
import ja from "@/locales/ja/strings.json"
import { getBrowserLang } from "../utils/getBrowserLang"

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "ja",
    lng:
      (typeof window !== "undefined" &&
        getBrowserLang(window.navigator.language)) ||
      "ja",
    resources: {
      en: {
        translation: en,
      },
      ja: {
        translation: ja,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .catch()

export default i18next
