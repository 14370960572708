import React from "react"
import { Alert, Slide, Snackbar } from "@mui/material"
import AlertContainer from "@/core/store/AlertContainer"
import CheckIcon from "@mui/icons-material/Check"

export const AlertMessage: React.FC = () => {
  const {
    state: { display, severity, message, icon },
    closeAlert,
  } = AlertContainer.useContainer()

  return (
    <Snackbar
      open={display}
      autoHideDuration={5000}
      onClose={closeAlert}
      anchorOrigin={
        severity === "success"
          ? { vertical: "bottom", horizontal: "right" }
          : { vertical: "top", horizontal: "center" }
      }
      TransitionComponent={props =>
        severity === "success" ? (
          <Slide {...props} direction="left" />
        ) : (
          <Slide {...props} direction="down" />
        )
      }
      sx={{ "& .MuiPaper-root": { color: "white" } }}
    >
      <Alert
        severity={severity}
        variant="filled"
        elevation={6}
        icon={icon}
        iconMapping={{ success: <CheckIcon fontSize="inherit" /> }}
      >
        <span className="whitespace-pre-line"> {message}</span>
      </Alert>
    </Snackbar>
  )
}
