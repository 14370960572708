import { Button, ButtonProps, useTheme } from "@mui/material"
import React, { PropsWithChildren } from "react"

type CommonButtonProps = PropsWithChildren<ButtonProps>

export const CommonButton: React.FC<CommonButtonProps> = ({
  children,
  sx = {},
  ...props
}) => {
  const { palette } = useTheme()
  return (
    <Button
      {...props}
      color={props.color || "primary"}
      className={`inline-flex justify-center items-center relative tracking-widest ${
        props.className || ""
      }`}
      sx={{
        fontFamily: "Noto Sans, Noto Sans JP, sans-serif",
        minWidth: "100%",
        fontSize: "15px",
        lineHeight: 1,
        padding: "10px",
        borderRadius: "5px",
        textTransform: "capitalize",
        "& .MuiButton-containedPrimary": {
          borderWidth: 1,
          borderColor: palette.primary.main,
          "&.Mui-disabled": {
            borderColor: "#9ca3af",
            color: "#9ca3af",
            backgroundColor: "#e5e7eb",
          },
          "&:not(.Mui-disabled):hover": { borderColor: palette.primary.dark },
        },
        "& .MuiButton-outlinedInfo": {
          borderColor: "#6b7280",
          color: "#6b7280",
          "&:hover": { backgroundColor: "#6b7280", color: "#ffffff" },
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}
