import { useRoutes, Navigate } from "react-router-dom"
import React, { ReactElement, Suspense } from "react"

const MainLayout = React.lazy(() =>
  import("../components/common/layout/MainLayout").then(({ MainLayout }) => ({
    default: MainLayout,
  })),
)

const HistoryPage = React.lazy(() =>
  import("../pages/history").then(({ HistoryPage }) => ({
    default: HistoryPage,
  })),
)

const ProductsPage = React.lazy(() =>
  import("../pages/products").then(({ ProductsPage }) => ({
    default: ProductsPage,
  })),
)

const NFTPage = React.lazy(() =>
  import("../pages/nft").then(({ NFTPage }) => ({ default: NFTPage })),
)

const PaymentPage = React.lazy(() =>
  import("../pages/payment").then(({ PaymentPage }) => ({
    default: PaymentPage,
  })),
)

const NotFoundPage = React.lazy(() =>
  import("../pages/404").then(({ NotFoundPage }) => ({
    default: NotFoundPage,
  })),
)

export const RouterMap = (): ReactElement | null =>
  useRoutes([
    {
      element: (
        <Suspense>
          <MainLayout />
        </Suspense>
      ),
      children: [
        {
          path: "/",
          element: (
            <Suspense>
              <ProductsPage />
            </Suspense>
          ),
        },
        {
          path: "/history",
          element: (
            <Suspense>
              <HistoryPage />
            </Suspense>
          ),
        },
        {
          path: "/payment",
          element: (
            <Suspense>
              <PaymentPage />
            </Suspense>
          ),
        },
        {
          path: "/nft/:id",
          element: (
            <Suspense>
              <NFTPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/404",
      element: (
        <Suspense>
          <NotFoundPage />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ])
