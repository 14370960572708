import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { RouterMap } from "@/router"
import { Layout } from "@/components/common/layout/Layout"
import "@/core/utils/resizeScreenHeight"
import "@/styles/globals.css"
import * as Sentry from "@sentry/react"

if (import.meta.env.VITE_ENV === "prod") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", import.meta.env.VITE_WEB_URL],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter>
    <Layout>
      <RouterMap />
    </Layout>
  </BrowserRouter>,
)
